import React from 'react'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { faCircle as circle} from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './circle.module.css'

export default function Cirlcles({index, current, setCurrentIndex}) {
  return (
    <div onClick={() => setCurrentIndex(index)} className={styles['each-circle']}>
        {index === current ? <FontAwesomeIcon icon={faCircle} /> : <FontAwesomeIcon icon={circle} />}
    </div>
  )
}
